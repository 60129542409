import axiosInstance from "src/utils/axiosInstance";

export const apiGetData = (url, config = {}) => axiosInstance.get(url, config);

// export const apiPostData = (url, body) => axiosInstance.post(url, body);
export const apiPostData = (url, body, config = {}) => {
    const _config= {headers: { "content-type": "application/json", Accept: "application/json" }, ...config}
    return axiosInstance.post(url, body, _config);
  };

export const apiUpdateData = (url, body) => axiosInstance.put(url, body);

export const apiDeleteData = (url) => axiosInstance.delete(url);
