import React from "react";
import Icon from "@mdi/react";
import styles from "./button.module.css";

const Button = ({
  text,
  color,
  icon,
  link,
  iconRight = false,
  type = "submit",
  onClick,
  size,
  className,
  rounded = false,
  iconSize = 1,
  ...rest
}) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={`${styles.btn} ${styles[color]} ${styles[size]} ${
        link ? styles.linkBtn : ""
      } ${!text && icon ? styles.onlyIcon : ""} ${
        rounded ? styles.rounded : ""
      } ${iconRight ? styles.icon_right : ""} ${className}`}
      // eslint-disable-next-line react/button-has-type
      type={type || "button"}
      onClick={onClick}
      {...rest}
    >
      {icon && (
        <Icon
          path={icon}
          size={iconSize}
          color={
            link || color === "bordered" || color === "light"
              ? "#546C5F"
              : "#fff"
          }
        />
      )}
      {text && text}
    </button>
  );
};

export default Button;
