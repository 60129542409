import Icon from '@mdi/react'
import React from 'react'
import { capitalizeWord } from 'src/constants/helper';
import styles from "./tableLabel.module.scss";


const TableLabel = ({
    label,
    color,
    icon,
    variant,
    iconRight = false,
    iconSize = 0.8,
    onClick,
    square=false,
    ...rest
}) => {

    return (
        <div>
            <div className={`d-flex gap-2 align-items-center ${styles.lable} ${color ? styles[color] : ""}  ${variant ? styles[variant] : ""}  ${iconRight ? styles.icon_right : ''} ${square ? styles.square : ''}`}
                {...rest}
            >
                {icon && (
                    <Icon
                        path={icon}
                        size={iconSize}
                        onClick={onClick}
                        style={{cursor: 'pointer'}}
                    />
                )}
                {capitalizeWord(label) || "NA"}
            </div>
        </div>

    )
}

export default TableLabel