import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Accordion, AccordionSummary } from '@mui/material';
import { dateFormatter, capitalizeWord, getToken } from 'src/constants/helper';
import Icon from '@mdi/react';
import { getPaymentById } from 'src/api';
import moment from 'moment';
import { mdiMenuUp } from '@mdi/js';
import NoContent from 'src/components/NoContent';
import TableLabel from "src/components/core/TableLabel";
import Button from "src/components/Button";
import { apiGetData,apiPostData } from 'src/services/api.service';
import { API_PROJECTS_FOR_PAYMENT } from 'src/constants/globalConstant';




import styles from './upcomingPayment.module.css';

const accordionStyles = {
  root: `${styles.accordian_styles}`,
};







const UpcomingPayment = () => {
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState([]);
  const [item, setItem] = useState([]);
  const selector = useSelector((state) => state.user);
  const params = useParams();

  const { id } = params;

  const getStatusValue = (value) => {
    if (value.status === 'paid') {
      return 'Paid';
    }
    const now = new Date();
    const timeDiff = new Date(value.dueDate).getTime() - now.getTime();
    return new Date(value.dueDate) >= now
      ? `${Math.floor(timeDiff / (1000 * 60 * 60 * 24))} days left`
      : `${Math.abs(Math.floor(timeDiff / (1000 * 60 * 60 * 24)))} days Overdue`;
  };

  // const getStatusValue = (value) => {
  //   console.log("VALUEEEE",value);
  //   if (value.status === 'paid') {
  //     return 'Paid';
  //   }
  //   const now = new Date();
  //   const dueDate = new Date(value.dueDate);
  
  //   if (dueDate < now) {
  //     return `${Math.abs(Math.floor((now - dueDate) / (1000 * 60 * 60 * 24)))} days Overdue`;
  //   }
  
  //   return 'Pending';
  // };
  
  
  const getStatusColor = (value) => {
    if (value.status === 'paid') {
      return 'green';
    }
    return new Date(value.dueDate) >= new Date() ? 'orange' : 'red';
  };
  

  const makePaymentByCustomer  = async (row, token) => {

    const { projectId, stage, amount } = row;
      const data = {
        stage,
        amount,
        userType: 'customer',
      }
      try {
        const url = `${API_PROJECTS_FOR_PAYMENT}/payByCustomer/${projectId}`; 
        const response = await apiPostData(url, data, token); 
        console.log("Payment Link:", response?.data?.response?.paymentLink);

        if (response?.data?.response?.paymentLink) {
         console.log("Payment made successfully");

         const paymentLink = response?.data?.response?.paymentLink;
         if (paymentLink) {
            window.open(paymentLink, '_blank');
         } else {
            console.error("Payment link not found in the response");
         }
        } else {
            console.error("Payment failed:", response.message);
        }
      } catch (error) {
        console.error("Error making payment:", error);
      }
};

  const handlePayment = (row) => {
    const token = getToken();
    makePaymentByCustomer(row, token);
  };

  const paymentPlan = async () => {
    setLoading(true);

    const response = await getPaymentById(selector.token, id);
    if (response && response.status === 200) {
      const data = response.data.response;
      setItem(data);
      if (data.payments.pending.length > 0) {
        setPending(data.payments.pending);
      } else {
        setPending([]);
      }
      setLoading(false);
    } else {
      toast.error(response?.response?.data?.statusMessage || response?.message);
      setLoading(false);
    }
  };
  const now = new Date();

  const firstOverdueItem = pending.find((item) => new Date(item.dueDate) < now);

  const columns = [
    {
      field: 'stage',
      headerName: 'Stage Name',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      align: 'start',
      renderCell: (params) => capitalizeWord(params.row.stage),
    },
    {
      field: 'amount',
      headerName: 'Amount ',
      disableColumnMenu: true,
      flex: 1,
      align: 'start',
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 1,
      align: 'start',
      renderCell: (params) => {
        return <p style={{ color: '#A17217', marginTop: '15px' }}>
          { dateFormatter(params.row.dueDate)}
          </p>;
      },
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   flex: 1,
    //   align: 'center',
    //   renderCell: (params) => (
    //     <TableLabel variant='outlined' label={getStatusValue(params.row)} color={getStatusColor(params.row)} />
    //   ),
    // },
  
    
    
    
    // {
    //   flex: 1,
    //   align: 'center',
    //   renderCell: (params) => {      
    //     return (
    //       <div style={{ textAlign: 'center' }}>
    //         <Button
    //         text="Pay Now"
    //         className={styles.button}
    //         // onClick={() => handlePayment(params.row)}
    //         type="submit"
    //         color="primary"
    //       />
  
    //       </div>
    //     );
    //   },
    // }
  
   

    // WORKING -last used
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      align: 'center',
      renderCell: (params) => {
        if (params.row._id === firstOverdueItem?._id) {
          // const daysOverdue = Math.abs(Math.floor((now - new Date(params.row.dueDate)) / (1000 * 60 * 60 * 24)));
          // return <TableLabel variant="outlined" label={`${daysOverdue} days Overdue`} color="red" />;
            return <TableLabel variant='outlined' label={getStatusValue(params.row)} color={getStatusColor(params.row)} />
        }
        return null; 
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      align: 'center',
      renderCell: (params) => {
        if (params.row.status !== 'pending') return null; 
    
        const now = new Date();
        const dueDate = new Date(params.row.dueDate);
        const daysLeft = Math.floor((dueDate - now) / (1000 * 60 * 60 * 24));
    
        const isOverdue = daysLeft < 0;
        const daysMessage = isOverdue ? `${Math.abs(daysLeft)} days overdue` : `${daysLeft} days left`;
    
        const pendingPayments = pending.filter((p) => p.status === 'pending');
        const isEarliestDue = pendingPayments.length > 0 && params.row._id === pendingPayments[0]._id;
    
        return (
          <div style={{ textAlign: 'center' }}>
            {isEarliestDue ? (
              <Button
                text="Pay Now"
                className={styles.button}
                onClick={() => handlePayment(params.row)}
                type="submit"
                color="primary"
              />
            ) : (
             <TableLabel variant="outlined" label={`${daysMessage}`} color="red" />

              // <TableLabel style={{ color: '#A17217' }}>{daysMessage}</TableLabel>
            )}
          </div>
        );
      },
    },
    
    
  ];
  

  useEffect(() => {
    paymentPlan();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className={styles.topItem}>
        <div style={{ textAlign: 'center' }}>
          <p className={styles.dataTable}>Paid Amount</p>
          <p style={{ color: '#07794C', fontWeight: '600', fontSize: '20px', lineHeight: '32px' }}>{item?.total}</p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p className={styles.dataTable}>Balance Amount</p>
          <p style={{ color: '#8A1E08', fontWeight: '600', fontSize: '20px', lineHeight: '32px' }}>{item?.pending}</p>
        </div>
      </div>
      {/* eslint-disable-next-line react/button-has-type */}

      <Accordion classes={accordionStyles} defaultExpanded>
        <AccordionSummary
          style={{ backgroundColor: '#EDF7F2', color: 'black' }}
          className={styles.dataTable}
          expandIcon={<Icon path={mdiMenuUp} size={0.8} color="#808080" />}
        >
          Upcoming Payments
        </AccordionSummary>
        {pending.length === 0 ? (
          <NoContent src="/assets/images/house.svg" title="No data available" isSmall />
        ) : (
          <DataGrid
            columns={columns}
            rows={pending}
            getRowId={(row) => row._id}
            className={styles.dataTable}
            autoHeight
            disableSelectionOnClick
            pagination={false}
            sx={{
              '& .MuiTablePagination-toolbar': {
                display: 'none',
              },
            }}
          />
        )}
      </Accordion>
    </div>
  );
};

export default UpcomingPayment;
