import React, { useState } from 'react';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import styles from './login.module.css';
import { updateUser } from '../../api';
import { update } from '../../store/userSlice';
import { URL_HOME } from '../../constants/routeConstants';



function Register() {

    const selector = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const updateUserDetails = async (data) => {
        setLoading(true)
        const response = await updateUser(selector.id, selector.token, { email: data.email, phoneNumber: data.phoneNumber, city: data.city,profileMeta: { ...data } })
        if (response && response.status === 200) {
            setLoading(false);
            dispatch(update({ ...data }));
            navigate(URL_HOME);
        } else {
            setLoading(false);
            toast.error(response.response.data.statusMessage || response.message)
        }
    }

    const schema = Yup.object(
        {
            name: Yup.string().max(25).required('Required'),
            email: Yup.string().email().required('Required'),
            pincode: Yup.string().min(6).max(6).required('Required'),
            phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required')
        }
    )

    const { values, handleChange, handleBlur, errors, handleSubmit, touched } = useFormik({
        initialValues: {
            name: selector.name || '',
            pincode: selector.pincode || '',
            email: selector.email || '',
            phoneNumber: selector.phoneNumber || '',
            city: selector.city || '',        
        },
        validationSchema: schema,
        onSubmit: data => {
            updateUserDetails(data)
        },
    });



    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', position: 'relative' }}>
            <h1 className={styles.registerHeading}>
                <span style={{ color: '#A8BB79', display: 'block', marginBottom: '2rem' }}>Add Your</span> Details Below
            </h1>
            <div style={{ position: 'absolute', marginTop: '18rem' }}>
                <form onSubmit={handleSubmit}>
                    <div style={{ display: 'flex', gap: '2rem', flexWrap: 'wrap' }}>
                        <label className={styles.registerLabel} htmlFor="name">Name
                            <input
                                id="name"
                                name="name"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                className={styles.inputField}
                            />
                            {errors.name && touched.name && <span style={{ color: 'red', marginTop: '.5rem', fontSize: '1.1rem', marginLeft: '.3rem' }}>{errors.name}</span>}
                        </label>
                        <label className={styles.registerLabel} htmlFor="email">Email
                            <input
                                id="email"
                                name="email"
                                type="email"
                                disabled={selector.isGoogle}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                className={styles.inputField}
                            />
                            {errors.email && touched.email && <span style={{ color: 'red', marginTop: '.5rem', fontSize: '1.1rem', marginLeft: '.3rem' }}>{errors.email}</span>}
                        </label>
                    </div>
                    <div style={{ display: 'flex', gap: '2rem', marginTop: '2rem', flexWrap: 'wrap' }}>
                        <label className={styles.registerLabel} htmlFor="phone">Phone
                            <input
                                id="phoneNumber"
                                name="phoneNumber"
                                type="text"
                                disabled={!selector.isGoogle}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phoneNumber}
                                className={styles.inputField}
                            />
                            {errors.phoneNumber && touched.phoneNumber && <span style={{ color: 'red', marginTop: '.5rem', fontSize: '1.1rem', marginLeft: '.3rem' }}>{errors.phoneNumber}</span>}
                        </label>
                        <label className={styles.registerLabel} htmlFor="pincode">Pincode
                            <input
                                id="pincode"
                                name="pincode"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.pincode}
                                className={styles.inputField}
                            />
                            {errors.pincode && touched.pincode && <span style={{ color: 'red', marginTop: '.5rem', fontSize: '1.1rem', marginLeft: '.3rem' }}>{errors.pincode}</span>}
                        </label>

                        
                    </div>
                    <div style={{ display: 'flex', gap: '2rem', marginTop: '2rem', flexWrap: 'wrap' }}>
                        <label className={styles.registerLabel} htmlFor="city">City
                            <input
                                id="city"
                                name="city"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.city}
                                className={styles.inputField}
                            />
                            {errors.city && touched.city && <span style={{ color: 'red', marginTop: '.5rem', fontSize: '1.1rem', marginLeft: '.3rem' }}>{errors.city}</span>}
                        </label>
                    </div>



                    <div style={{ textAlign: 'center' }}>
                        <Button disabled={loading} variant='contained' type="submit" className={styles.saveButton}>Save Details</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Register;