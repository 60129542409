import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Menu, MenuItem } from '@mui/material';
// import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LogoutIcon from '@mui/icons-material/Logout';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Typography from '@mui/material/Typography';
import styles from './navbar.module.css';

import { logout } from '../../store/userSlice';
import LoginRequestModal from '../loginRequestModal/LoginRequestModal';
import { URL_ABOUT, URL_CHECKLIST, URL_CONTACT, URL_ESTIMATE, URL_EXPERTS, URL_FEEDS, URL_HOME, URL_INSPIRATION, URL_LOGIN, URL_PROFILE, URL_QUIZ } from '../../constants/routeConstants';

function Navbar() {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.user);
  const [anchorElement, setAnchorElement] = useState(null);
  const [profileElement, setProfileElement] = useState(null);
  const [profileListOpen, setProfileListOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [loginRequestModal, setLoginRequestModal] = useState(false);
  const isloggedIn = selector.token && selector.name && selector.email && selector.phoneNumber

  const handleClose = () => {
    setAnchorElement(null);
    setOpen(false);
  };
  const handleClick = (e) => {
    setAnchorElement(e.currentTarget);
    setOpen(true);
  };
  const profileHandleClick = (e) => {
    setProfileElement(e.currentTarget);
    setProfileListOpen(true);
  };
  const ProfileClose = () => {
    setProfileElement(null);
    setProfileListOpen(false);
  };

  const navigate = useNavigate();
  const handleExpert = () => {
    if (selector.token) {
      navigate(URL_EXPERTS);
    } else {
      setLoginRequestModal(true);
    }
  };
  const menuItemClicked = (path) => {
    // setAnchorElement(false);
    navigate(path);
    setTimeout(handleClose, 100);
  };

  return (
    <div className={styles.navContainer}>
      <div className={styles.logoContainer}>
        <div>
        <img style={{width:'220px'}} className={styles.logoLarge} role="presentation" onClick={() => navigate(URL_HOME)} src="/assets/images/buildLogoWhite.svg" alt="logo" />
        <img style={{width:'150px'}} className={styles.logoSmall} role="presentation" onClick={() => navigate(URL_HOME)} src="/assets/images/buildLogoWhite.svg" alt="logo" />
        <h2 className={styles.brand_company}>Tallam Shilpa Private Limited</h2>
        </div>
        <div className={styles.location}>Bangalore<img style={{ marginLeft: '.1rem' }} src="/assets/icons/whiteLocation.svg" alt="" /></div>
      </div>
      <div style={{ flexGrow: '1', display: 'flex', justifyContent: 'center' }}>
        <div className={styles.searchContainer}>
          {/* <img className={styles.searchIcon} src="/assets/images/search.svg" alt="search" />
          <input className={styles.searchInput} type="text" name="search" id="search" placeholder="What are you looking for ?" /> */}
        </div>

        <div style={{ display: 'flex' }}>
          <Typography role="presentation" className={styles.navItem} onClick={() => navigate(URL_INSPIRATION)}>
            Inspiration
          </Typography>
          <Typography role="presentation" className={styles.navItem} onClick={() => navigate(URL_EXPERTS)}>
            Experts
          </Typography>
          <Typography role="presentation" className={styles.navItem} onClick={() => navigate(URL_FEEDS)}>
            Feeds
          </Typography>
          <Typography className={`${styles.navItem} `} onClick={handleClick}>
            More <img style={{ width: '10px', marginLeft: '2px' }} src="/assets/icons/downArrow.svg" alt="" />
          </Typography>
        </div>
        <Menu
          autoFocus={false}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              padding: '2.4rem',
              mt: 1.5,
              '& .MuiSvgIcon-root': {
                float: 'right',
                position: 'absolute',
                right: '5px',
                top: '14px !important',
                fontSize: '1.5rem',
              },
              '& .MuiMenu-list': {
                // padding: '2.6rem',
                minWidth: '22rem',
              },

              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 14,
                height: 14,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          anchorEl={anchorElement}
          open={open}
          onClose={handleClose}
        >
          {isloggedIn && <MenuItem disableRipple className={styles.dropdownList} onClick={() => menuItemClicked(URL_QUIZ)}>
            Take A Quiz
            <span style={{ float: 'right', marginTop: '3px' }}>
              <ArrowForwardIosIcon />
            </span>
          </MenuItem>}
          {/* <MenuItem className={styles.dropdownList} onClick={handleClose}>
            Discussion
            <span style={{ float: 'right', marginTop: '3px' }}>
              <ArrowForwardIosIcon />
            </span>
          </MenuItem> */}
          <MenuItem className={styles.dropdownList} onClick={() => menuItemClicked(URL_ESTIMATE)}>
            Get Estimate
            <span style={{ float: 'right', marginTop: '3px' }}>
              <ArrowForwardIosIcon />
            </span>
          </MenuItem>
          {isloggedIn && <MenuItem className={styles.dropdownList} onClick={() => menuItemClicked(URL_CHECKLIST)}>
            Get CheckList
            <span style={{ float: 'right', marginTop: '3px' }}>
              <ArrowForwardIosIcon />
            </span>
          </MenuItem>}
          <MenuItem className={styles.dropdownList} onClick={() => menuItemClicked(URL_CONTACT)}>
            Contact Us
            <span style={{ float: 'right', marginTop: '3px' }}>
              <ArrowForwardIosIcon />
            </span>
          </MenuItem>
          <MenuItem className={styles.dropdownList} onClick={() => menuItemClicked(URL_ABOUT)}>
            About Us
            <span style={{ float: 'right', marginTop: '3px' }}>
              <ArrowForwardIosIcon />
            </span>
          </MenuItem>
        </Menu>
      </div>
      <div className={styles.iconsContainer}>
        {/* <Typography>
          <ShoppingCartIcon style={{ color: '#fff', fontSize: '2rem' }} />
        </Typography> */}
        <Typography style={{cursor:'pointer'}} onClick={selector.token ? ()=>navigate(`/profile/inspiration-board`) : ()=>setLoginRequestModal(true)}>
          <FavoriteIcon style={{ color: '#fff', fontSize: '2rem' }} />
        </Typography>
        <Typography className={styles.profile} onClick={profileHandleClick}>
          <AccountCircleIcon style={{ color: '#fff', fontSize: '2rem' }} />
        </Typography>
      </div>


      {selector.token && selector.name && selector.email && selector.phoneNumber && (
        <Menu
          autoFocus={false}
          anchorEl={profileElement}
          open={profileListOpen}
          onClose={ProfileClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '& .MuiMenu-list': {
                padding: '2rem',
                minWidth: '18rem',
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 14,
                height: 14,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem className={styles.dropdownLists} style={{ borderBottom: '0.5px solid rgba(0, 0, 0, 0.1)' }}>
            Hello,
            <br />
            {selector.name}
          </MenuItem>
          <MenuItem component={Link} to={URL_PROFILE} className={styles.dropdownLists}>
            <span>
              <PersonOutlineIcon style={{ fontSize: '2rem', float: 'left', marginRight: '1rem' }} />
            </span>
            My Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(logout());
              ProfileClose();
            }}
            className={styles.dropdownLists}
            style={{ borderTop: '0.5px solid rgba(0, 0, 0, 0.1)', marginBottom: '20px' }}
          >
            <LogoutIcon style={{ fontSize: '1.6rem', float: 'left', marginRight: '1rem' }} /> Logout{' '}
          </MenuItem>
        </Menu>
      )}
      {!(selector.token && selector.name && selector.email && selector.phoneNumber) && (
        <Menu
          autoFocus={false}
          anchorEl={profileElement}
          open={profileListOpen}
          onClose={ProfileClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '& .MuiMenu-list': {
                padding: '2rem',
                minWidth: '18rem',
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 14,
                height: 14,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={() => navigate(URL_LOGIN)} variant="contained" className={styles.headerButton}>
              Sign In
            </Button>
          </div>
          <MenuItem className={styles.signup}>
            New User?
            <b style={{ marginLeft: '.5rem' }} role="presentation" onClick={() => navigate('/login')}>
              Sign Up?
            </b>
          </MenuItem>
        </Menu>
      )}
      <LoginRequestModal setModal={setLoginRequestModal} modal={loginRequestModal} />
    </div>
  );
}

export default Navbar;
