/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    toastType: 'success', 
    showToaster: false,
    toastText: 'Successful Toast',
};

const toasterSlice = createSlice({
    name: 'toaster',
    initialState,
    reducers: {
        showToaster: (state, action) => {
            state.toastType = action.payload.type;
            state.toastText = action.payload.text;
            state.showToaster = true
        },
        closeToaster: (state) => {
            state.showToaster = false;
            state.toastText = '';
        }
    }
});

// selectors 
export const toastTypeSelector = (state) => state.toaster.toastType;
export const toasterStatusSelector = (state) => state.toaster.showToaster;
export const toastTextSelector = (state) => state.toaster.toastText;

// actions
export const {showToaster, closeToaster} = toasterSlice.actions;

export default toasterSlice.reducer;